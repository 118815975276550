import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import WorkSlideshow from "../components/workSlideshow";

const IndexPage = (props) => {
  return (
    <Layout location={props.location}>
      <WorkSlideshow work={props} />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query indexQuery {
    allFile(limit: 1, filter: { sourceInstanceName: { eq: "work" } }) {
      edges {
        node {
          name
          sourceInstanceName
          childMarkdownRemark {
            frontmatter {
              work_items {
                date
                dimensions
                image {
                  publicURL
                  childImageSharp {
                    original {
                      src
                    }
                    fluid(maxHeight: 600) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                material
                work_title
              }
            }
          }
        }
      }
    }
  }
`;
